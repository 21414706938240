import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, { style: { background: '#fff', padding: '24px', margin: 0, minHeight: '280px' } }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, [
              _createVNode(_component_a_form, {
                layout: "inline",
                model: _ctx.param
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleQuery()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 查询 ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.add()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 新增 ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            (_ctx.level1.length > 0)
              ? (_openBlock(), _createBlock(_component_a_table, {
                  key: 0,
                  columns: _ctx.columns,
                  "row-key": record => record.id,
                  "data-source": _ctx.level1,
                  pagination: false,
                  loading: _ctx.loading,
                  defaultExpandAllRows: true
                }, {
                  action: _withCtx(({ text, record }) => [
                    _createVNode(_component_a_space, { size: "small" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: ($event: any) => (_ctx.showModal(record))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" 编辑 ")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_a_popconfirm, {
                          title: "该操作会同时删除分类下的所有电子书以及其文档且删除后不可恢复，您确定要删除吗？",
                          "ok-text": "是",
                          "cancel-text": "否",
                          onConfirm: ($event: any) => (_ctx.handleDelete(record.id))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, { type: "danger" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" 删除 ")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onConfirm"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["columns", "row-key", "data-source", "loading"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      visible: _ctx.open,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.open) = $event)),
      title: "分类表单",
      "confirm-loading": _ctx.confirmLoading,
      onOk: _ctx.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.category,
          "label-col": { span:6},
          "wrapper-col": { span: 18 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "名称" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.category.name,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.category.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "父分类" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  ref: "select",
                  value: _ctx.category.parent,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.category.parent) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select_option, { value: "0" }, {
                      default: _withCtx(() => [
                        _createTextVNode("无")
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.level1, (c) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: c.id,
                        value: c.id,
                        disabled: _ctx.category.id === c.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(c.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "顺序" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.category.sort,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.category.sort) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "confirm-loading", "onOk"])
  ], 64))
}