
import {defineComponent, onMounted, ref} from 'vue';
import axios from 'axios';
import {message} from 'ant-design-vue'
import { Tool } from '@/util/tool';

export default defineComponent({
  name: 'AdminEbook',
  setup() {
    const param = ref();
    param.value = {};
    const ebooks = ref();
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0
    });
    const loading = ref(false);

    const columns = [
      {
        title: '封面',
        dataIndex: 'cover',
        slots: {customRender: 'cover'}
      },
      {
        title: '名称',
        dataIndex: 'name'
      },
      {
        title: '分类',
        slots: { customRender: 'category' }
      },
      {
        title: '文档数',
        dataIndex: 'docCount'
      },
      {
        title: '阅读数',
        dataIndex: 'viewCount'
      },
      {
        title: '点赞数',
        dataIndex: 'voteCount'
      },
      {
        title: 'Action',
        key: 'action',
        slots: {customRender: 'action'}
      }
    ];
    const handleQuery = (params: any) => {
      loading.value = true;
      axios.get("/ebook/list", {
        params:{
          page:params.page,
          size:params.size,
          name:param.value.name
        }
      }).then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success){
          ebooks.value = data.content.list;
          // 重置分页按钮
          pagination.value.current = params.page;
          pagination.value.total=data.content.total;
        }else {
          message.error(data.message);
        }
      });
    };
    /**
     * 表格点击页码时触发
     */
    const handleTableChange = (pagination: any) => {
      console.log("看看自带的分页参数都有啥：" + pagination);
      handleQuery({
        page: pagination.current,
        size: pagination.pageSize
      });
    };
    onMounted(() => {
      handleQueryCategory();

    });

    const open = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const modalText = ref<string>('Content of the modal');
    const ebook=ref();
    const level1 =  ref();
    let categorys: any;
    const categoryIds=ref();

    const handleQueryCategory = () => {
      loading.value = true;
      axios.get("/category/all").then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success){
          categorys = data.content;
          console.log("原始数组：",categorys);
          level1.value = [];
          level1.value = Tool.array2Tree(categorys, 0);
          console.log("树形结构：", level1);

          //先加载分类，后加载电子书，否则分类树加载很慢，电子书渲染会报错
          handleQuery({
            page:1,
            size:pagination.value.pageSize
          });
        }else {
          message.error(data.message);
        }
      });
    };
    const getCategoryName = (cid: number) => {
      // console.log(cid)
      let result = "";
      categorys.forEach((item: any) => {
        if (item.id === cid) {
          // return item.name; // 注意，这里直接return不起作用
          result = item.name;
        }
      });
      return result;
    };

    const showModal = (record:any) => {
      open.value = true;
      ebook.value=Tool.copy(record);
      categoryIds.value = [ebook.value.category1Id, ebook.value.category2Id]
    };

    const handleOk = () => {
      confirmLoading.value = true;
      ebook.value.category1Id = categoryIds.value[0];
      ebook.value.category2Id = categoryIds.value[1];
      axios.post("/ebook/save",ebook.value).then((response) => {
        confirmLoading.value = false;
        const data = response.data;
        if(data.success){
          open.value = false;
          handleQuery({
            page:pagination.value.current,
            size:pagination.value.pageSize
          });
        }else {
          message.error(data.message);
        }
      });
    };
    const add=()=>{
      open.value = true;
      ebook.value={};
    }

    const handleDelete=(id:number)=>{
      console.log(id);
      axios.delete("/ebook/delete/"+id).then((response) => {
        const data = response.data;
        if(data.success){
          //重新加载列表
          handleQuery({
            page:pagination.value.current,
            size:pagination.value.pageSize
          });
        }
      });
    }

    return {
      param,
      ebooks,
      pagination,
      columns,
      loading,
      handleTableChange,
      confirmLoading,
      modalText,
      handleOk,
      open,
      add,
      handleDelete,
      showModal,
      handleQuery,
      ebook,
      categoryIds,
      level1,
      getCategoryName
    }
  }
});
