
import store from '@/store';
import { message } from 'ant-design-vue';
import axios from 'axios';
import {computed, defineComponent, ref } from 'vue';
declare let hexMd5: any;
declare let KEY: any;
export default defineComponent({
  name: 'TheHeader',
  setup () {
    // 登录后保存
    const user = computed(() => store.state.user);
    // 用来登录
    const loginUser = ref({
      loginName: "",
      password: ""
    });
    const loginModalVisible = ref(false);
    const loginModalLoading = ref(false);
    const showLoginModal = () => {
      //登录之前清空文本框
      loginUser.value.loginName="";
      loginUser.value.password="";
      loginModalVisible.value = true;
    };



    // 登录
    const login = () => {
      console.log("开始登录");

      loginModalLoading.value = true;
      // loginUser.value.password = hexMd5(loginUser.value.password + KEY);
      const encryptedLoginUser = {
        //浅拷贝
        ...loginUser.value,
        password: hexMd5(loginUser.value.password + KEY),//重新赋值
      };
      axios.post('/user/login', encryptedLoginUser).then((response) => {
        loginModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          loginModalVisible.value = false;
          message.success("登录成功！");
          store.commit("setUser",data.content);
        } else {
          message.error(data.message);
        }
      });
    };

    // 退出登录
    const logout = () => {
      console.log("退出登录开始");
      axios.get('/user/logout/' + user.value.token).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success("退出登录成功！");
          store.commit("setUser", {});
        } else {
          message.error(data.message);
        }
      });
    };
    return{
      loginModalVisible,
      loginModalLoading,
      showLoginModal,
      loginUser,
      login,
      logout,
      user
    }
  }
});
