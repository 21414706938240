
import {defineComponent, onMounted, ref} from 'vue';
import axios from 'axios';
import {message} from 'ant-design-vue'
import { Tool } from '@/util/tool';

export default defineComponent({
  name: 'AdminCategory',
  setup() {
    const param = ref();
    param.value = {};
    const categorys = ref();
    const loading = ref(false);

    /**
     * 一级分类树，children属性就是二级分类
     * [{
     *   id: "",
     *   name: "",
     *   children: [{
     *     id: "",
     *     name: "",
     *   }]
     * }]
     */
    const level1 = ref(); // 一级分类树，children属性就是二级分类
    level1.value = [];

    const columns = [
      {
        title: '名称',
        dataIndex: 'name'
      },
      {
        title: '父分类',
        key: 'parent',
        dataIndex: 'parent'
      },
      {
        title: '顺序',
        dataIndex: 'sort'
      },
      {
        title: 'Action',
        key: 'action',
        slots: {customRender: 'action'}
      }
    ];
    const handleQuery = () => {
      loading.value = true;
      axios.get("/category/all").then((response) => {
        loading.value = false;
        const data = response.data;
        if(data.success){
          categorys.value = data.content;
          console.log("原始数组：",categorys.value);
          level1.value = [];
          level1.value = Tool.array2Tree(categorys.value, 0);
          console.log("树形结构：", level1);
        }else {
          message.error(data.message);
        }
      });
    };
    /**
     * 表格点击页码时触发
     */
    onMounted(() => {
      handleQuery();
    });

    const open = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const modalText = ref<string>('Content of the modal');
    const category=ref({});

    const showModal = (record:any) => {
      open.value = true;
      category.value=Tool.copy(record);
      console.log(record);
    };

    const handleOk = () => {
      confirmLoading.value = true;
      axios.post("/category/save",category.value).then((response) => {
        confirmLoading.value = false;
        const data = response.data;
        if(data.success){
          open.value = false;
          handleQuery();
        }else {
          message.error(data.message);
        }
      });
    };
    const add=()=>{
      open.value = true;
      category.value={};
    }

    const handleDelete=(id:number)=>{
      console.log(id);
      axios.delete("/category/delete/"+id).then((response) => {
        const data = response.data;
        if(data.success){
          //重新加载列表
          handleQuery();
        }
      });
    }

    return {
      param,
      columns,
      loading,
      confirmLoading,
      modalText,
      handleOk,
      open,
      add,
      handleDelete,
      showModal,
      handleQuery,
      category,
      level1
    }
  }
});
