import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, { style: { background: '#fff', padding: '24px', margin: 0, minHeight: '280px' } }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, [
              _createVNode(_component_a_form, {
                layout: "inline",
                model: _ctx.param
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.param.name,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.param.name) = $event)),
                        placeholder: "名称"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleQuery({page:1,size:_ctx.pagination.pageSize})))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 查询 ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.add()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 新增 ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _createVNode(_component_a_table, {
              columns: _ctx.columns,
              "row-key": record => record.id,
              "data-source": _ctx.ebooks,
              pagination: _ctx.pagination,
              loading: _ctx.loading,
              onChange: _ctx.handleTableChange
            }, {
              cover: _withCtx(({ text: cover }) => [
                cover
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: cover,
                      alt: "avatar",
                      width: "50",
                      height: "50"
                    }, null, 8, _hoisted_1))
                  : _createCommentVNode("", true)
              ]),
              category: _withCtx(({ text, record }) => [
                _createElementVNode("span", null, _toDisplayString(_ctx.getCategoryName(record.category1Id)) + " / " + _toDisplayString(_ctx.getCategoryName(record.category2Id)), 1)
              ]),
              action: _withCtx(({ text, record }) => [
                _createVNode(_component_a_space, { size: "small" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: '/admin/admin-doc?ebookId='+record.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, { type: "primary" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" 文档管理 ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: ($event: any) => (_ctx.showModal(record))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 编辑 ")
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_a_popconfirm, {
                      title: "该操作将同时删除此电子书下的所有文档且删除后不可恢复，您确定要删除吗？",
                      "ok-text": "是",
                      "cancel-text": "否",
                      onConfirm: ($event: any) => (_ctx.handleDelete(record.id))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, { type: "danger" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" 删除 ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onConfirm"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["columns", "row-key", "data-source", "pagination", "loading", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      visible: _ctx.open,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.open) = $event)),
      title: "电子书表单",
      "confirm-loading": _ctx.confirmLoading,
      onOk: _ctx.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.ebook,
          "label-col": { span:6},
          "wrapper-col": { span: 18 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "封面" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.ebook.cover,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ebook.cover) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "名称" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.ebook.name,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ebook.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "分类" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_cascader, {
                  value: _ctx.categoryIds,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.categoryIds) = $event)),
                  "field-names": { label: 'name', value: 'id', children: 'children' },
                  options: _ctx.level1
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "描述" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.ebook.description,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.ebook.description) = $event)),
                  type: "textarea"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "confirm-loading", "onOk"])
  ], 64))
}