import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "doc" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "vote-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_LikeOutlined = _resolveComponent("LikeOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, { style: { background: '#fff', padding: '24px', margin: 0, minHeight: '280px' } }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.level1.length === 0)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_2, "对不起，找不到相关文档！"))
              : _createCommentVNode("", true),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 6 }, {
                  default: _withCtx(() => [
                    (_ctx.level1.length > 0)
                      ? (_openBlock(), _createBlock(_component_a_tree, {
                          key: 0,
                          "tree-data": _ctx.level1,
                          onSelect: _ctx.onSelect,
                          replaceFields: {title: 'name', key: 'id', value: 'id'},
                          defaultExpandAll: true,
                          defaultSelectedKeys: _ctx.defaultSelectedKeys
                        }, null, 8, ["tree-data", "onSelect", "defaultSelectedKeys"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 18 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.doc.name), 1),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, "阅读数：" + _toDisplayString(_ctx.doc.viewCount), 1),
                        _createTextVNode("     "),
                        _createElementVNode("span", null, "点赞数：" + _toDisplayString(_ctx.doc.voteCount), 1)
                      ]),
                      _createVNode(_component_a_divider, { style: {"height":"2px","background-color":"#9999cc"} })
                    ]),
                    _createElementVNode("div", {
                      class: "wangeditor",
                      innerHTML: _ctx.html
                    }, null, 8, _hoisted_3),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        shape: "round",
                        size: 'large',
                        onClick: _ctx.vote
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_LikeOutlined),
                          _createTextVNode("  点赞：" + _toDisplayString(_ctx.doc.voteCount), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}